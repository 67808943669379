<template>
  <div class="box">
    <div class="topChange">
      <div class="item" @click="onclick(0)" :class="{ on: isSelect == 0 }">
        班型
      </div>
      <div class="item" @click="onclick(1)" :class="{ on: isSelect == 1 }">
        课程
      </div>
      <div class="selectBg" :class="isSelect == 1 ? 'left' : 'right'"></div>
    </div>
    <div class="search">
      <!-- 班型搜索 -->
      <div v-if="this.isSelect == 0">
        <el-select v-model="classesId" @change="onChangeClass" placeholder="全部班型" clearable>
          <el-option v-for="item in schoolList" :key="item.value" :label="item.tiitle"
            :value="item.classType + ',' + item.id">
          </el-option>
        </el-select>
        <el-select placeholder="全部课程" @change="onSearch" v-model="search.keId" clearable>
          <el-option v-for="item in kcList" :key="item.kcId" :label="item.kcName" :value="item.kcId">
          </el-option>
        </el-select>
        <el-date-picker clearable @change="onSearch" value-format="yyyy-MM-dd" v-model="timer" type="daterange"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <!-- 课程搜索 -->
      <div v-if="this.isSelect == 1">
        <el-select v-if="this.selectTYpe == 4" placeholder="全部课程" @change="onSearch" v-model="course" clearable>
          <el-option @change="onSearch" v-for="item in courseList" :key="item.courseId" :label="item.courseName"
            :value="`${item.courseType}+','+${item.courseId}`">
          </el-option>
        </el-select>
        <el-select v-else placeholder="全部课程" @change="onSearch" v-model="course" clearable>
          <el-option @change="onSearch" v-for="item in courseList" :key="item.courseId" :label="item.courseName"
            :value="item.courseId">
          </el-option>
        </el-select>
        <el-date-picker clearable value-format="yyyy-MM-dd" v-model="timer" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="onSearch">
        </el-date-picker>
      </div>
      <div class="typeButton">
        <div class="typeItem" @click="checkType(0)" :class="{ ison: selectTYpe == 0 }">
          直播
        </div>
        <div class="typeItem" @click="checkType(1)" :class="{ ison: selectTYpe == 1 }">
          录播
        </div>
        <div v-if="isSelect == 0" class="typeItem" @click="checkType(2)" :class="{ ison: selectTYpe == 2 }">
          考场
        </div>
        <!-- <div
          class="typeItem"
          @click="checkType(3)"
          :class="{ ison: selectTYpe == 3 }"
        >
          试题
        </div> -->
        <div class="typeItem" @click="checkType(4)" :class="{ ison: selectTYpe == 4 }">
          资料
        </div>
      </div>
      <recordTable :type="selectTYpe" :tab="isSelect" :dataList="dataList" :videoList="videoList" :examList="examList"
        :liveList="liveList" />
    </div>
    <el-pagination class="pagination" :total="total" :current-page="pageNum" :page-sizes="[8, 16, 24, 40]"
      :page-size="pageSize" :pager-count="5" @current-change="handleCurrentChange" @size-change="handleSizeChange"
      background layout="total, sizes, prev, pager, next">
    </el-pagination>
  </div>
</template>

<script>
import { getInfo } from "@/api/cookies";
import {
  selectStuClassesListByStudentId,
  selectPcStuLiveRecordByClassIdKeId,
  selectCourseNameByClassesId,
  selectPcStuVideoRecordByClassIdKeId,
  selectPcStuExamRecordByClassIdKeId,
  selectPcStuPaperRecordByClassIdKeId,
  selectPcStuMaterialRecordByClassIdKeId,
  selectPcStuLiveRecordBySingleKeId,
  selectPcStuVideoRecordBySingleIdKeId,
  selectPcStuMaterialRecordBySingleIdKeId,
  selectStuCourseListByStudentId,
} from "@/api/know";
import recordTable from "./recordTable";
export default {
  data() {
    return {
      course: null,
      isSelect: 0,
      selectTYpe: 0,
      pageSize: 8,
      pageNum: 1,
      userInfo: getInfo(),
      tableData: [],
      classesId: null,
      timer: null,
      search: {
        keId: null,
      },
      schoolList: [],
      total: 0,
      kcList: [],
      courseList: [],
      liveList: [],
      videoList: [],
      examList: [],
      dataList: [],
      total: 0,
    };
  },
  components: {
    recordTable,
  },
  created() {
    // 获取全部班型列表
    this.getClassList();
    this.getCurseList();
    // 获取学习记录列表
    this.getList();
  },
  methods: {
    // 获取全部班型
    getClassList() {
      selectStuClassesListByStudentId({
        studentId: this.userInfo.id,
      }).then((res) => {
        this.schoolList = res.msg;
      });
    },
    // 单科tab下 获取全部课程列表
    getCurseList() {
      selectStuCourseListByStudentId({
        studentId: this.userInfo.id,
      }).then((res) => {
        this.courseList = res.msg;
      });
    },
    // 根据班型获取课程
    getkc(val) {
      selectCourseNameByClassesId({
        classesId: val,
      }).then((res) => {
        this.kcList = res.msg;
      });
    },
    // 秒转时分秒
    exchangeTime(time) {
      if (time <= 0) {
        return 0 + "秒";
      }
      // 总秒数
      let seconds = time;
      let h = Math.floor(seconds / 60 / 60);
      let m = Math.floor((seconds / 60) % 60);
      let s = Math.floor(seconds % 60);
      if (h > 0 && m > 0 && s > 0) {
        return h + "时" + m + "分" + s + "秒";
      } else if (h <= 0 && m > 0 && s > 0) {
        return m + "分" + s + "秒";
      } else if (h <= 0 && m <= 0 && s > 0) {
        return s + "秒";
      }
    },
    // 获取表格内容
    getList() {
      // 页面加载
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.isSelect == 0) {
        if (this.selectTYpe == 0) {
          // 获取直播列表
          selectPcStuLiveRecordByClassIdKeId({
            keId: this.search.keId ? this.search.keId : undefined,
            classesId: this.classesId ? this.classesId : undefined,
            startTime: this.timer ? this.timer[0] : null,
            endTime: this.timer ? this.timer[1] : null,
            studentId: this.userInfo.id,
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          }).then((res) => {
            this.liveList = res.rows;
            // 计算学习时间
            this.liveList.forEach((item, index) => {
              item["studyTime"] =
                +new Date(item.endTime) - +new Date(item.recordingTime);
              item["studyTime"] = this.exchangeTime(item["studyTime"] / 1000);
            });
            this.total = res.total;
            loading.close();
          });
          // 获取录播列表
        } else if (this.selectTYpe == 1) {
          selectPcStuVideoRecordByClassIdKeId({
            keId: this.search.keId ? this.search.keId : undefined,
            classesId: this.classesId ? this.classesId : undefined,
            startTime: this.timer ? this.timer[0] : null,
            endTime: this.timer ? this.timer[1] : null,
            studentId: this.userInfo.id,
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          }).then((res) => {
            this.videoList = res.rows;
            // 计算学习时间
            this.videoList.forEach((item, index) => {
              item["studyTime"] =
                +new Date(item.endTime) - +new Date(item.recordingTime);
              item["studyTime"] = this.exchangeTime(item["studyTime"] / 1000);
            });
            this.total = res.total;
            loading.close();
          });
          // 获取考场列表
        } else if (this.selectTYpe == 2) {
          selectPcStuExamRecordByClassIdKeId({
            keId: this.search.keId ? this.search.keId : undefined,
            classesId: this.classesId ? this.classesId : undefined,
            startTime: this.timer ? this.timer[0] : null,
            endTime: this.timer ? this.timer[1] : null,
            studentId: this.userInfo.id,
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          }).then((res) => {
            this.examList = res.rows;
            // 计算开始时间
            this.examList.forEach((item, index) => {
              let timdedetail =
                +new Date(item.submitTime) - item.answerTimes * 1000;
              //获取年份
              var year = new Date(timdedetail).getFullYear();
              //获取月份，获取的月份比实际小1，所以在使用的时候需要+1
              var month = new Date(timdedetail).getMonth() + 1;
              //获取日
              var date = new Date(timdedetail).getDate();
              //获取时
              var hours = new Date(timdedetail).getHours();
              //获取分
              var minutes = new Date(timdedetail).getMinutes();
              //获取秒
              var seconds = new Date(timdedetail).getSeconds();
              //组合格式为年-月-日 时：分：秒（2021-7-5 21:21:21）
              var time =
                year +
                "-" +
                (month >= 10 ? month : "0" + month) +
                "-" +
                (date >= 10 ? date : "0" + date) +
                " " +
                (hours >= 10 ? hours : "0" + hours) +
                ":" +
                (minutes >= 10 ? minutes : "0" + minutes) +
                ":" +
                (seconds >= 10 ? seconds : "0" + seconds);
              item["recordingTime"] = time;
              item["recordingTime"] = time;
              item["answerTimes"] = this.exchangeTime(item["answerTimes"]);
            });
            this.total = res.total;
            loading.close();
          });
        } else if (this.selectTYpe == 4) {
          selectPcStuMaterialRecordByClassIdKeId({
            keId: this.search.keId ? this.search.keId : undefined,
            classesId: this.classesId ? this.classesId : undefined,
            startTime: this.timer ? this.timer[0] : null,
            endTime: this.timer ? this.timer[1] : null,
            studentId: this.userInfo.id,
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          }).then((res) => {
            this.dataList = res.rows;
            this.total = res.total;
          });
          loading.close();
        }
      } else if (this.isSelect == 1) {
        if (this.selectTYpe == 0) {
          // 获取直播列表
          selectPcStuLiveRecordBySingleKeId({
            keId: this.course ? this.course : undefined,
            startTime: this.timer ? this.timer[0] : null,
            endTime: this.timer ? this.timer[1] : null,
            studentId: this.userInfo.id,
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          }).then((res) => {
            this.liveList = res.rows;
            // 计算学习时间
            this.liveList.forEach((item, index) => {
              item["studyTime"] =
                +new Date(item.endTime) - +new Date(item.recordingTime);
              item["studyTime"] = this.exchangeTime(item["studyTime"] / 1000);
            });
            this.total = res.total;
            loading.close();
          });
          // 获取录播列表
        } else if (this.selectTYpe == 1) {
          selectPcStuVideoRecordBySingleIdKeId({
            keId: this.course ? this.course : undefined,
            startTime: this.timer ? this.timer[0] : null,
            endTime: this.timer ? this.timer[1] : null,
            studentId: this.userInfo.id,
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          }).then((res) => {
            this.videoList = res.rows;
            // 计算学习时间
            this.videoList.forEach((item, index) => {
              item["studyTime"] =
                +new Date(item.endTime) - +new Date(item.recordingTime);
              item["studyTime"] = this.exchangeTime(item["studyTime"] / 1000);
            });

            this.total = res.total;
            loading.close();
          });
        }
        // 获取资料列表
        else if (this.selectTYpe == 4) {
          selectPcStuMaterialRecordBySingleIdKeId({
            keId: this.course ? this.course : undefined,
            startTime: this.timer ? this.timer[0] : null,
            endTime: this.timer ? this.timer[1] : null,
            studentId: this.userInfo.id,
            pageSize: this.pageSize,
            pageNum: this.pageNum,
          }).then((res) => {
            this.dataList = res.rows;
            this.total = res.total;
            loading.close();
          });
        }
      }

    },
    onChangeClass() {
      // 清空课程
      this.search.keId = "";
      this.onSearch();
    },
    // 切换最顶部tab页
    onclick(val) {
      this.isSelect = val;
      this.search.keId = "";
      this.timer = "";
      this.classesId = null;
      this.selectTYpe = 0;
      this.course = "";
      this.onSearch();
    },
    // 切换类型
    checkType(val) {
      this.selectTYpe = val;
      this.onSearch(val);
    },
    // 触发搜索事件
    onSearch() {
      this.pageSize = 8;
      this.pageNum = 1;
      this.getList();
    },

    /* 切换每页条数 */
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
  },
  watch: {
    classesId: {
      deep: true,
      handler(val) {
        this.getkc(val);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
  padding: 25px 32px;

  .topChange {
    position: relative;
    width: 272px;
    height: 38px;
    background: #f5f7f9;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    display: flex;

    .item {
      text-align: center;
      line-height: 38px;
      flex: 1;
      z-index: 1;
      cursor: pointer;
    }

    .selectBg {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 132px;
      height: 30px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
    }
  }

  .search {
    margin-top: 28px;

    ::v-deep .el-select {
      width: 200px;
      height: 43px;
      margin-right: 16px;

      &:nth-of-type(1) {
        margin-left: 0px;
      }

      .el-input__inner {
        width: 200px !important;
        background: #f5f7f9;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
      }

      & ::placeholder {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .el-date-editor--daterange {
      width: 260px;
      background: #f5f7f9;
    }

    ::v-deep .el-range-input {
      background: #f5f7f9;
    }

    ::v-deep .el-range-separator {
      width: 10%;
    }
  }

  .typeButton {
    display: flex;
    margin-top: 24px;
    margin-bottom: 22px;

    .typeItem {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      width: 76px;
      height: 28px;
      background: #f5f7f9;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      text-align: center;
      line-height: 28px;
      margin-right: 12px;
      cursor: pointer;
    }

    .ison {
      background: #1061ff;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #1061ff;
      color: #ffffff;
    }
  }

  .el-pagination {
    width: auto;
    height: auto;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep .active {
      background: #1061ff !important;
    }
  }

  .on {
    color: #1061ff;
  }

  .right {
    left: 4px !important;
  }

  .left {
    left: 133px !important;
  }
}
</style>